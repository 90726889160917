import { ActionIcon, Alert, Anchor, Avatar, Button, Checkbox, CloseButton, Divider, Drawer, Flex, Group, Paper, Switch, Text, Tooltip } from "@mantine/core";
import { IconCheckbox, IconCircleMinus, IconFileText, IconMailForward } from "@tabler/icons";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NetworkApi } from "../../apis";
import { LimitSetForm } from "../../components/buyer-offer/limit-set-form";
import { FormLayout, PageLayout } from "../../components/layout";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const SupplierOffer = () => {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = useState<any>([]);
  const tableRef = useRef<ITableRef>(null);
  const { programParticipantStatus, networkPenaltyTypes, networkDiscountTypes = [] } = useSelector((state: { general: IGeneral }) => state.general);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<any>({ query: "" });
  const [selected, setSelected] = useState<any[]>([]);

  const columns = useHeader({
    programParticipantStatus,
    networkPenaltyTypes,
    networkDiscountTypes,
    selected,
    setSelected,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          setAction(["details", item]);
          break;

        case "edit":
          setAction([]);
          break;

        default:
          break;
      }
    },
  });

  const reload = async () => {
    await tableRef.current?.reload();
    setSelected([]);
  };

  return (
    <PageLayout
      title="Supplier-ийг санал болгох"
      subTitle="Supplier бизнесүүдийн авлагын лимитийн нөхцөл байдал"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <Button
            disabled={selected.length <= 0}
            onClick={() => {
              setAction(["set_limit", selected]);
            }}>
            Лимит тохируулах
          </Button>
        </Group>,
      ]}>
      <Alert mb={"sm"} title="Санамж!" closeButtonLabel="">
        Доорх бизнесийн нэхэмжлэхүүд хөтөлбөрт хамааралгүй буюу хамрагдаагүй.
      </Alert>

      <Text color="gray" fz={"sm"} fw={800} pl={"xs"}>
        <Flex gap={8} align={"center"}>
          <IconCheckbox size={18} />
          Сонгосон бизнес: {selected?.length || 0}
        </Flex>
      </Text>
      <Table ref={tableRef} minWidth={2400} name="requests.list" columns={columns} filters={filters} loadData={(data) => NetworkApi.list(data!)} />
      <Drawer
        styles={{ drawer: { overflowY: "scroll" } }}
        position="right"
        size="80%"
        title={false}
        withCloseButton={false}
        opened={action[0] === "set_limit"}
        onClose={() => {
          setSelected([]);
          setAction([]);
        }}>
        <Paper px={"lg"}>
          <FormLayout
            title={"Харилцагч Supplier-ийн авлагын лимит тохируулах"}
            extra={[
              <div key={0}>
                <CloseButton
                  onClick={() => {
                    setAction([]);
                  }}
                />
              </div>,
            ]}>
            <Divider mb={"lg"} />
            <LimitSetForm setAction={setAction} reload={reload} action={action} selected={selected} setSelected={setSelected} />
          </FormLayout>
        </Paper>
      </Drawer>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  programParticipantStatus,
  networkPenaltyTypes,
  networkDiscountTypes,
  selected,
  setSelected,
}: {
  onClick: (e1: string, e2: any) => void;
  programParticipantStatus: IReference[];
  networkPenaltyTypes: IReference[];
  networkDiscountTypes: IReference[];
  selected: any[];
  setSelected: (e1: any) => void;
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    dataIndex: "description",
    render: (record) => {
      return (
        <Group noWrap>
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconFileText />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Устгах" position="bottom">
            <ActionIcon disabled variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconCircleMinus />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Хяналтад илгээх" position="bottom">
            <ActionIcon disabled variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconMailForward />
            </ActionIcon>
          </Tooltip>
        </Group>
      );
    },
  },
  {
    title: "",
    renderTitle: (data) => {
      return (
        <Checkbox
          checked={selected.length > 0}
          onChange={(e) => {
            if (!e.target.checked) {
              setSelected([]);
            } else {
              setSelected(data?.rows?.filter((row: any) => row.isSelectable) || []);
            }
          }}
        />
      );
    },
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {
            if (selected.some((item: any) => item.id === record.id)) {
              setSelected(selected.filter((item: any) => item.id !== record.id));
            } else {
              setSelected([...selected, record]);
            }
          }}
          checked={selected.some((item: any) => item.id === record.id)}
          disabled={!record.isSelectable}
        />
      );
    },
    width: "30px",
  },
  {
    title: "ТТД",
    dataIndex: "sku",
    render: (record) => {
      return <Text w={"max-content"}>{record?.regNumber || "-"}</Text>;
    },
  },
  {
    title: "Партнерийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return record?.partner ? (
        <Text w={"max-content"}>
          <Text>{record?.partner?.businessName || "-"}</Text>
          <Anchor># {record?.partner?.refCode || "-"}</Anchor>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Supplier бизнесийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Text>{record?.profileName || "-"} </Text>
          <Anchor># {record?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    dataIndex: "sku",
    render: (record) => {
      return Tag(
        programParticipantStatus?.find((item: IReference) => item?.code === record?.participantStatus)?.color || "gray",
        programParticipantStatus?.find((item: IReference) => item?.code === record?.participantStatus)?.name || "-",
      );
    },
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Text>{record?.areaRegion?.name || "-"}</Text>
        </Text>
      );
    },
  },
  {
    title: "Чиглэл",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Text>{record?.areaDirection?.name || "-"}</Text>
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    dataIndex: "sku",
    render: (record) => {
      return record?.paymentTerm ? (
        <Text w={"max-content"}>
          <Text color={"golomt"}>#{record?.paymentTerm?.refCode}</Text>
          <Text color="dimmed">{record?.paymentTerm?.description}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгчээс олгосон авлага лимит",
    dataIndex: "sku",
    width: 120,
    render: (record) => <Text align="end">{currencyFormat(record?.debtLimitAmount || 0)}</Text>,
  },
  {
    title: "Лимит эхлэх огноо",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{dateTimeFormat(record.debtLimitStartDate)}</Group>;
    },
  },
  {
    title: "Лимит дуусах огноо",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{dateTimeFormat(record.debtLimitEndDate)}</Group>;
    },
  },
  {
    title: "Хөнгөлөлттэй эсэх",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Switch checked={record?.earlyDiscount} offLabel={<Text fz={"10px"}>Үгүй</Text>} onLabel={<Text fz={"10px"}>Тийм</Text>} />
        </div>
      );
    },
  },
  {
    title: "Хөнгөлөлт (хувь)",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{record?.discountPercent || "0"} %</Group>;
    },
  },
  {
    title: "Хөнгөлөлт арга",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          {networkDiscountTypes?.length > 0 ? networkDiscountTypes.find((item: IReference) => item.code === record.discountType)?.name || "-" : "-"}
        </Text>
      );
    },
  },
  {
    title: "Алдангитай эсэх",
    dataIndex: "sku",
    render: (record) => {
      return (
        <>
          <Switch checked={record?.countPenalty} offLabel={<Text fz={"10px"}>Үгүй</Text>} onLabel={<Text fz={"10px"}>Тийм</Text>} />
        </>
      );
    },
  },
  {
    title: "Алданги (хувь)",
    dataIndex: "sku",
    render: (record) => {
      return <>{record?.penaltyPercent || 0} %</>;
    },
  },
  {
    title: "Алданги арга",
    dataIndex: "sku",
    render: (record) => {
      return <Text w={"max-content"}>{networkPenaltyTypes?.find((item: IReference) => item.code === record.penaltyType)?.name || "-"} </Text>;
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 7 хоног",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.weekAvgDebtAmount)}
        </Group>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 14 хоног",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.halfMonthAvgDebtAmount)}
        </Group>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - Сар",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgDebtAmount)}
        </Group>
      );
    },
  },
  {
    title: "Буцаалтын дундаж дүн - Сар",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgRefundAmount)}
        </Group>
      );
    },
  },
  {
    title: "Сарын дундаж татан авалт",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgPurchaseAmount)}
        </Group>
      );
    },
  },
  {
    title: "Сарын дундаж орлого тушаалт",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgBidAmount)}
        </Group>
      );
    },
  },
  {
    title: "Ангилал",
    dataIndex: "sku",
    render: (record) => {
      if (record?.classificationCategory)
        return (
          <Text w={"max-content"}>
            <Text>{record?.classificationCategory?.name || "-"}</Text>
            <Text size={"sm"} color="dimmed" truncate>
              {record?.classificationCategory?.description || "-"}
            </Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Зэрэглэл",
    dataIndex: "sku",
    render: (record) => {
      if (record?.classificationPriority)
        return (
          <Text w={"max-content"}>
            <Text>{record?.classificationPriority?.name || "-"}</Text>
            <Text maw={"320px"} size={"sm"} color="dimmed" truncate>
              {record?.classificationPriority?.description || "-"}
            </Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Хариуцсан санхүү",
    dataIndex: "sku",
    render: (record) => {
      if (record?.areaSetUser)
        return (
          <Group noWrap>
            <Avatar src={record?.areaSetUser?.avatar || ""} />
            <Text>
              {record?.areaSetUser?.lastName ? record.areaSetUser.lastName[0] + ". " : ""} {record?.areaSetUser?.firstName || ""}
            </Text>
          </Group>
        );
      else return "-";
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Supplier-ийн санал",
  },
];
